<template>
    <v-flex xs12 sm12>
        <v-overlay>
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-flex>
    
</template>
<script>
export default {
    props:{
        showloader: {
            type: Boolean,
            default:false
        },
    }
}
</script>